import axios from "axios";

const BASIC_URL=process.env.REACT_APP_N7_OFFCHAIN_API_URL;

const getCpList = () => {
    const url=BASIC_URL + "/cps";
    return axios.get(url);
}

const getCpId = (cpName) => {
    let cp = {
        cpName: cpName
    }
    const url=BASIC_URL;
    return axios.post(url, cp);
}

const getCpByHash = (cpHashId) => {
    const url = BASIC_URL + "/" + cpHashId;
    return axios.get(url);
}

const getUserInfo = (cpUserName) => {
    const encodedCpName = encodeURIComponent(cpUserName);
    const url=BASIC_URL + "/cpUser/" + encodedCpName;
    return axios.get(url);
}

const addNewCp = (cpUser) => {
    const url = BASIC_URL + "/cpUser";
    return axios.post(url, cpUser);
}

export {getCpList, getCpId, getCpByHash, getUserInfo, addNewCp};